import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {StoreModel} from "../models/store.model";
import {StoreServiceHttp} from "./store.service-http";
import {catchError, finalize, map} from "rxjs/operators";
import {ErrorHandlingService} from "../../../../../shared/services/error-handling.service";
import {FileHttpService} from "../../../../../shared/services/files/file-http.service";
import {OrganizationModel} from "../../../models/organization.model";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentStore: BehaviorSubject<StoreModel> = new BehaviorSubject<StoreModel>(null);
  private currentStoreLocalStorageToken = `${environment.appVersion}-${environment.STORE_KEY}`;


  constructor(
    private readonly storeServiceHttp: StoreServiceHttp,
    private errorHandlingService: ErrorHandlingService,
    private fileService: FileHttpService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  get currentStoreValue(): StoreModel {
    return this.currentStore.value;
  }

  set currentStoreValue(store: StoreModel) {
    this.currentStore.next(store);
  }
  createStore(data: Omit<StoreModel, 'id'>): Observable<StoreModel> {
    this.isLoadingSubject.next(true);
    return this.storeServiceHttp.createStore(data).pipe(
      map((data: StoreModel) => {
        this.setCurrentStoreLocalStorage(data);
        return data;
      }),
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }


  updateFile(file: File) {
    return this.fileService.updateFile(file).pipe(
      map((data: StoreModel) => {
        return data;
      }),
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }


  setCurrentStoreLocalStorage(data: StoreModel) {
    localStorage.setItem(this.currentStoreLocalStorageToken, JSON.stringify(data))
  }


  getStoreLocalStorage(): OrganizationModel | undefined {
    try {

      const lsValue = localStorage.getItem(this.currentStoreLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      return undefined;
    }

  }

}
