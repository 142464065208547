import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AppModel} from "../models/app.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private appStateLocalStorageKey = `${environment.appVersion}-${environment.APP_STATE_KEY}`;

  currentAppStatewSubject: BehaviorSubject<AppModel> = new BehaviorSubject<AppModel>(null)

  countryNetwork: BehaviorSubject<ILocationInfo> = new BehaviorSubject<ILocationInfo>(null);

  constructor(private http: HttpClient) {

  }

  get currentAppStateValue(): AppModel {
    return this.currentAppStatewSubject.value;
  }

  set currentAppStateValue(state: AppModel) {
    this.setAppStateFromLocalStorage(state)
    this.currentAppStatewSubject.next(state);
  }

  getCountryNetwork() {
    return this.http.get('http://ip-api.com/json').subscribe((res: any) => {
      this.countryNetwork.next(res);
    });
  }

  getCountryNetworkValue() {
    return this.countryNetwork.asObservable();
  }


  private setAppStateFromLocalStorage(state: AppModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (state && state.user) {
      localStorage.setItem(this.appStateLocalStorageKey, JSON.stringify(state));
      return true;
    }
    return false;
  }

  private getAppStateFromLocalStorage(): AppModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.appStateLocalStorageKey);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }


}


export interface ILocationInfo {
  status: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  city: string;
  zip: string;
  lat: number;
  lon: number;
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string;
}
