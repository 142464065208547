import {Injectable, Injector, OnDestroy} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {
  codeVerificationRequest, JwtPayloadType,
  sendCodeVerificationRequest,
  SigninRequest,
  SignUpRequest, UserModel
} from '../models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Route, Router} from '@angular/router';
import {ErrorHandlingService} from "../../../shared/services/error-handling.service";
import {AppModel} from "../../../shared/models/app.model";
import {OrganizationService} from "../../organization/features/organization/services/organization.service";
import {OrganizationModel} from "../../organization/models/organization.model";
import {OrganizationHttpService} from "../../organization/features/organization/services/organization-http.service";
import {AppService} from "../../../shared/services/app.service";
import {StoreService} from "../../organization/features/store/services/store.service";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private authLocalStorageVerifyPhone = `${environment.appVersion}-${environment.VERIFY_PHONE_KEY}`;
  private currentStoreLocalStorageToken = `${environment.appVersion}-${environment.STORE_KEY}`;
  private currentOrganizationLocalStorageToken = `${environment.appVersion}-${environment.ORGANIZATION_KEY}`;


  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private organizationHttpService: OrganizationHttpService,
    private errorHandlingService: ErrorHandlingService,
    private appService: AppService,
    private injector: Injector
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    //const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(data: SigninRequest): Observable<AppModel> {
    this.setPhoneFromLocalStorage(data);
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(data).pipe(
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginVerify(data: codeVerificationRequest): Observable<AppModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginVerify(data).pipe(
      map((auth: JwtPayloadType) => {
        this.setAuthFromLocalStorage(auth);
      }),
      switchMap(() => this.initUserData()),
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    this.currentUserSubject.next(null)
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.currentStoreLocalStorageToken);
    localStorage.removeItem(this.currentOrganizationLocalStorageToken);
    if (this.router.url !== '/auth/registration') {
      this.router.navigate(['/auth/login'], {
        queryParams: {},
      });
    }
  }


  refreshUserData() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserType) => {
        this.currentUserSubject.next(user);
        return user;
      })
    )
  }

  initUserData(): Observable<AppModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      switchMap((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
          return this.organizationHttpService.getUserOrganizations();
        } else {
          this.logout();
        }
      }),
      map((organizations: OrganizationModel[]) => {
        const state = {user: this.currentUserSubject.value, organizations};
        this.injector.get(AppService).currentAppStateValue = state;
        this.setDefaultStoreAndOrganization(state);
        return state;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  setDefaultStoreAndOrganization(appState: AppModel) {
    if (appState.organizations && appState.organizations.length > 0) {
      const organizationService = this.injector.get(OrganizationService);
      organizationService.currentOrganizationValue = appState.organizations[0];
      organizationService.setOrganiozationLocalStorage(appState.organizations[0])
      if (appState.organizations[0]?.stores && appState.organizations[0].stores.length > 0) {
        const storeService = this.injector.get(StoreService);
        storeService.currentStoreValue = appState.organizations[0].stores[0];
        storeService.setCurrentStoreLocalStorage(appState.organizations[0].stores[0])
      }
    }
  }


  // need create new user then login
  registration(user: SignUpRequest): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      switchMap((auth: JwtPayloadType) => {
         this.setAuthFromLocalStorage(auth);
         return this.refreshUserData();
      }),
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  sendSmsVerificationCode(request: sendCodeVerificationRequest): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .sendPhoneVerificationCode(request.recipient)
      .pipe(
        catchError((err) => {
          const errorMessage = this.errorHandlingService.handleError(err);
          return throwError(() => errorMessage);
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  sendEmailVerificationCode(request: sendCodeVerificationRequest): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .sendEmailVerificationCode(request.recipient)
      .pipe(
        catchError((err) => {
          const errorMessage = this.errorHandlingService.handleError(err);
          return throwError(() => errorMessage);
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  verificationCode(request: codeVerificationRequest): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verificationCode(request)
      .pipe(
        switchMap((res) => {
          if (res.verified) {
            return this.refreshUserData();
          }
          return res;
        }),
        catchError((err) => {
          const errorMessage = this.errorHandlingService.handleError(err);
          return throwError(() => errorMessage);
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  verificationEmailCode(request: codeVerificationRequest): Observable<{ verified: boolean }> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verificationEmailCode(request)
      .pipe(
        switchMap((res) => {
          if (res.verified) {
            return this.refreshUserData();
          }
          return of(res);
        }),
        catchError((err) => {
          const errorMessage = this.errorHandlingService.handleError(err);
          return throwError(() => errorMessage);
        }),
        finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: JwtPayloadType): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): JwtPayloadType | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getPhoneFromLocalStorage(): {phoneNumber:string} | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageVerifyPhone);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      return undefined;
    }

  }

  setPhoneFromLocalStorage(data) {
    return localStorage.setItem(this.authLocalStorageVerifyPhone, JSON.stringify(data));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
