import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AppService} from "../../shared/services/app.service";
import {StoreService} from "./features/store/services/store.service";
import {OrganizationService} from "./features/organization/services/organization.service";


@Injectable({providedIn: 'root'})
export class OrganizationGuard {
  constructor(private appService: AppService,
              private router: Router,
              private storeService: StoreService,
              private organizationService: OrganizationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organization = this.organizationService.getOrganizationLocalStorage();
    if (organization) {
      const currentStore = this.storeService.getStoreLocalStorage();
      if (currentStore) {
        return true;
      }else{
        this.router.navigate([`organization/${organization.id}/store`]);
        return false;
      }
    }
    this.router.navigate([`organization/create`]);
    // not logged in so redirect to login page with the return url
    return false;
  }
}
