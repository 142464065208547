// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appVersion: 'v1.0.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    ORGANIZATION_KEY: 'org2e58f138d18c',
    STORE_KEY: 'storef592cfe0daa5',
    VERIFY_PHONE_KEY: 'verifyphone649fc9a5f55',
    APP_STATE_KEY: 'appstate649fc9a5f55',
    isMockEnabled: true,
    apiUrl: 'http://localhost:3000/api',
    authApiUrl: 'https://clicapp-ms-auth.lab.clicapp.africa/api/auth',
    accountApiUrl: 'https://clicapp-ms-account.lab.clicapp.africa/api/account',
    notificationApiUrl:
        'https://clicapp-ms-notification.lab.clicapp.africa/api/notification',
    organizationApiUrl:
        'https://clicapp-ms-organization.lab.clicapp.africa/api/organization',
    storageApiUrl: 'https://clicapp-ms-storage.lab.clicapp.africa/api/storage',
    catalogApiUrl: 'https://clicapp-ms-catalog.lab.clicapp.africa/api/catalog',
    appThemeName: 'Clicapp',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
