import {STATUS} from 'angular-in-memory-web-api'
import {CA, TH} from 'src/app/shared/components/phone-number/resources/svg'

// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    LAYOUT: {
      HEADER: {
        LOGOUT: 'Déconnexion',
      },
    },
    MENU: {
      NEW: 'Nouveau',
      ACTIONS: 'Actes',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Tableau de Bord',
      ORDERS: 'Commandes',
      ALL: 'Liste',
      REPPORT: 'Rapport',
      CATALOG: 'Catalogue',
      PRODUCTS: 'Produits',
      CATEGORIES: 'Catégories',
      CUSTOMER: 'Clients',
      DESIGN: 'Design',
      STORE_FRONT: 'Boutique',
      CHECKOUT: 'Commandes',
      APPEARANCE: 'Apparence',
      SETTINGS: 'Paramètres',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Pas encore de compte?',
        HAS_ACCOUNT: 'Déjà un compte?',
        SIGNUP_TEXT: "S'inscrire",
        SIGNIN_TEXT: 'Se connecter',
        SIGNUP_BUTTON: "S'inscrire",
        FORGOT_BUTTON: 'Mot de passe oublié ?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Connexion',
        DESCRIPTION: 'Veuillez vous connecter pour continuer',
        BUTTON: 'Continuer',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Inscription',
        DESCRIPTION:
          'Nous vous enverrons un code de vérification par SMS',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      VERIFY: {
        TITLE: 'Vérification du N° de téléphone',
        DESCRIPTION: 'Entrez le code de vérification envoyé par SMS au',
        TYPE_CODE: 'Entrez le code de vérification à 6 chiffres',
        DONT_GET_CODE: "Vous n'avez pas reçu le code?",
        RESEND_CODE: 'Renvoyer',
        BUTTON: 'Envoyer',
        LOADING_MESSAGE: 'Verification en cours....',
        CODE_INVALID: 'Le code de vérification est invalide',
      },
      REGISTER_PASSWORD: {
        DESCRIPTION:
          'Veuillez remplir le formulaire ci-dessous pour terminer votre inscription',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nom & prénoms',
        FIRSTNAME: 'Prénom',
        LASTNAME: 'Nom',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmer le mot de passe',
        USERNAME: "Nom d'utilisateur",
        PHONE: 'Téléphone',
      },
    },
    CATEGORIES: {
      LIST: {
        TITLE: 'Liste des Catégories',
        BTN_ADD: 'Ajouter une catégorie',
        INPUT_SERACH: 'Rechercher une catégorie',
      },
      ADD: {
        TITLE: 'Ajouter une catégorie',
        INPUT: {
          NAME: 'Nom',
          NAME_PLACEHOLDER: 'Nom de la catégorie',
          NAME_TIPS:
            'Le nom de la catégorie est obligatoire et doit être unique.',
          DESCRIPTION: 'Description',
          DESCRIPTION_TIPS:
            'Définissez une description de la catégorie pour une meilleure visibilité.',

          THUMBNAIL: 'Image de la catégorie',
          THUMBNAIL_TIPS:
            'Définissez l’image miniature de la catégorie. Seuls les fichiers image *.png, *.jpg et *.jpeg sont acceptés.',
          STATUS: 'Statut',
          STATUS_PLACEHOLDER: 'Statut de la catégorie',
          STATUS_TIPS: 'Définissez le statut de la catégorie',
          INFO_GENERAL: 'Informations générales',
          BTN_CANCEL: 'Annuler',
          BTN_SAVE: 'Enregistrer',
          SUCCESS_MESSAGE: 'La catégorie a été créée avec succès',
        },
      },
      EDIT: {
        TITLE: 'Modifier la catégorie',
        INPUT: {
          SUCCESS_MESSAGE: 'La catégorie a été modifiée avec succès',
        },
      },
    },
    PRODUCT: {
      LIST: {
        TITLE: 'Liste des Produits',
        BTN_ADD: 'Ajouter un produit',
        INPUT_SERACH: 'Rechercher un produit',
      },
      ADD: {
        TITLE: 'Produit',
        BREADCRUMB: 'Ajouter un produit',
        INPUT: {
          NAME: 'Nom',
          NAME_PLACEHOLDER: 'Nom du produit',
          VISIBILITY: 'Visibilité',
          CATEGORY: 'Catégorie',
          CATEGORY_PLACEHOLDER: 'Rechercher ou créer une catégorie',
          TYPE: 'Type',
          PRICE: 'Prix',
          PROMO_PRICE: "Prix de promotion",
          NORMAL_PRICE: 'Prix normal',
          VARIANT_NAME: 'Nom',
          VARIANT: 'Variante',
          VARIANT_NAME_PLACEHOLDER: 'Nom de la variante',
          VARIANT_BUY_PRICE: "Prix d'achat",
          VARIANT_SELL_PRICE: 'Prix de vente',
          DESCRIPTION: 'Description',
          DESCRIPTION_TIPS:
            'Définissez une description du produit pour une meilleure visibilité.',
          IMAGES: 'Images',
          VARIANTE: 'Variantes',
          BTN_ADD_VARIANTE: 'Ajouter une variante',
          OPTIONS: 'Options',
          OPTION: 'Option',
          OPTION_NAME_PLACEHOLDER: "Nom de l'option",
          OPTION_TYPE: 'Type',
          OPTION_TYPE_PLACEHOLDER: "Type d'option",
          OPTION_REQUIRED: 'Obligatoire',
          OPTION_CHOICE: 'Choix',
          OPTION_CHOICE_NAME: 'Nom',
          OPTION_CHOICE_BTN_ADD: 'Ajouter un choix',
          BTN_ADD_OPTION: 'Ajouter une option',
          BTN_SAVE: 'Enregistrer',
          THUMBNAIL: 'Image du produit',
          THUMBNAIL_TIPS:
            'Définissez l’image miniature du produit. Seuls les fichiers image *.png, *.jpg et *.jpeg sont acceptés.',
          STATUS: 'Statut',
          STATUS_PLACEHOLDER: 'Statut du produit',
          STATUS_TIPS: 'Définissez le statut du produit',
          INFO_GENERAL: 'Informations générales',
          BTN_CANCEL: 'Annuler',
          SUCCESS_MESSAGE: 'Le produit a été créé avec succès',
          INPUT_UPLOAD: {
            TITLE: 'Glissez, déposez ou cliquez pour télécharger',
            SUBTITLE: '6 fichiers maximum et 2MB par fichier',
            NOTE: 'Seuls les fichiers image *.png, *.jpg et *.jpeg sont acceptés.',
          },


        },
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT:
          "Nombre d'enregistrements sélectionnés: ",
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'éCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Les clients',
        CUSTOMERS_LIST: 'Liste des clients',
        NEW_CUSTOMER: 'Nouveau client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Suppression du client',
          DESCRIPTION:
            'Êtes-vous sûr de supprimer définitivement ce client?',
          WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
          MESSAGE: 'Le client a été supprimé',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Supprimer les clients',
          DESCRIPTION:
            'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
          WAIT_DESCRIPTION: 'Les clients suppriment ...',
          MESSAGE: 'Les clients sélectionnés ont été supprimés',
        },
        UPDATE_STATUS: {
          TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
          MESSAGE:
            'Le statut des clients sélectionnés a été mis à jour avec succès',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Le client a été mis à jour',
          ADD_MESSAGE: 'Le client a été créé',
        },
      },
    },
    ORGANIZATION: {
      TITLE: 'Mettons en place votre entreprise',
      SUBTITLE: 'Fournir les informations  de votre entreprise.',
      INPUT: {
        NAME: "Nom de l'entreprise",
        NAME_PLACEHOLDER: 'XYZ Boutique',
        SECTOR: "Secteur d'activité",
        SECTOR_PLACEHOLDER: "Choisir votre secteur d'activité",
        SIZE: "Taille de l'entreprise",
        SIZE_PLACEHOLDER: 'Choisir la taille de votre entreprise',
        BTN_SAVE: 'Enregistrer',
      },
    },
    STORE: {
      TITLE: 'Creer une nouvelle boutique',
      SUBTITLE:
        'Saisissez les informations essentielles de votre boutique.',
      INPUT: {
        STORE_LOGO: 'Logo de la boutique',
        STORE_NAME: 'Nom de la boutique',
        STORE_NAME_PLACEHOLDER: 'XYZ Boutique',
        STORE_WHATSAPP_PHONE_NUMBER: 'Numéro WhatsApp',
        STORE_WHATSAPP_PHONE_NUMBER_PLACEHOLDER:
          'Numéro de téléphone WhatsApp',
        STORE_WHATSAPP_PHONE_NUMBER_TIPS:
          'Les clients enverront leurs commandes à ce numero',
        STORE_LINK: 'Lien de la boutique',
        STORE_LINK_TIPS:
          'Ce sera le lien vers votre boutique. Vous pourrez passer à un domaine personnalisé ultérieurement.',
        BTN_CREATE: 'Créer',
      },
    },
    VERIFY_EMAIL_MODAL: {
      TITLE: 'Vérifiez votre adresse e-mail',
      INPUT: {
        EMAIL: 'Adresse e-mail',
        BTN_SUBMIT: 'Envoyer',
        BTN_CANCEL: 'Annuler',
      },
    },
    VERIFY_CODE_MODAL: {
      DESCRIPTION:
        "Entrez le code de vérification envoyé à l'adresse e-mail",
      TYPE_CODE: 'Entrez le code de vérification à 6 chiffres',
      DONT_GET_CODE: "Vous n'avez pas reçu le code?",
      RESEND_CODE: 'Renvoyer',
      BUTTON: 'Envoyer',
      LOADING_MESSAGE: 'Verification en cours....',
      CODE_INVALID: 'Le code de vérification est invalide',
      SUCCESS_VERIFICATION:
        'Votre adresse e-mail a été vérifiée avec succès',
    },
    SHARED: {
      LOADING_MESSAGE: 'Chargement en cours...',
      LOADING_MESSAGE_SHORT: 'En cours...',
      ERRORS: {
        ERROR_OCCURED:
          "Une erreur s'est produite veuiillez réessayer plus tard.",
      },
      SUCCESS: {
        OTP_SEND_SUCCESS:
          'Le code de vérification a été envoyé avec succès',
        DELETE_SUCCESS: "L'élément a été supprimé avec succès",
      },
      WARNING: {
        DELETE: 'Êtes-vous sûr de vouloir supprimer cet élément?',
        NO_ROLLBACK: 'Cette action est irréversible.',
      },
      BTN: {
        LOGOUT: 'Déconnexion',
        CANCEL: 'Annuler',
        SAVE: 'Enregistrer',
      },
      VALIDATION: {
        INVALID: "{{name}} n'est pas valide",
        REQUIRED: 'Ce champ est obligatoire',
        MIN_LENGTH:
          'La longueur minimale est de {{requiredLength}} caractères',
        MIN: 'La valeur minimale est {{min}}',
        MAX: 'La valeur maximale est {{max}}',
        AGREEMENT_REQUIRED:
          "L'acceptation des termes et conditions est requise",
        NOT_FOUND: "Le {{name}} demandé n'a pas été trouvé",
        INVALID_LOGIN: 'Les détails de connexion sont incorrects',
        REQUIRED_FIELD: 'Champ obligatoire',
        MIN_LENGTH_FIELD: 'Longueur minimale du champ :',
        MAX_LENGTH_FIELD:
          'La longueur maximale est de {{requiredLength}} caractères',
        INVALID_FIELD: "Le champ n'est pas valide",
        INVALID_PHONE_NUMBER: "Le numéro de téléphone n'est pas valide",
        ERROR_OCCURED:
          "Une erreur s'est produite veuiillez réessayer plus tard.",
        ERROR_PASSWORD_MISMATCH:
          'Les mots de passe ne correspondent pas',
        ONLY_LETTER_NUMBER:
          'Seuls les lettres et les chiffres sont autorisés',
        STORE_PATH: "Le lien choisi n'est pas valide.",
        EMAIL: "L'adresse email n'est pas valide",
        PATTERN_EMAIL: "L'adresse email n'est pas valide",
      },
      STATUS: {
        ACTIVE: 'Activé',
        INACTIVE: 'Désactivé',
      },
    },
  },
}
