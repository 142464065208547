import {inject, Injectable, Injector} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from "../../modules/auth";
import {environment} from "../../../environments/environment";
import {StoreService} from "../../modules/organization/features/store/services/store.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  private excludedRoutes = ['/auth/login', '/auth/register','/auth/registration','http://ip-api.com/json'];
  constructor(private router: Router,private storeService: StoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.excludedRoutes.some(route => req.url.includes(route))) {
      const token = this.getToken();
      if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        });

        if (req.url.includes('/catalog') || req.url.includes('/media')) {
          const storeId = this.storeService.currentStore.value?.id;
          if(storeId){
            // Ajoutez l'entête x-store-id si l'URL concerne le catalogue
            req = req.clone({
              headers: req.headers.set('x-store-id', storeId.toString())
            });
          }
        }
      }else{
        this.logout(req);
      }
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.router.navigate(['/auth/login']);
        }
        return throwError(error);
      })
    );
  }


  getToken(){
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);

      if(!authData || !authData.token){
        return undefined;
      }
      return authData.token;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout(req?: HttpRequest<any>) {
    localStorage.removeItem(this.authLocalStorageToken);
    console.log('req.url', req.url);
    if (!this.excludedRoutes.some(route => req.url.includes(route))) {
       /*this.router.navigate(['/auth/login'], {
         queryParams: {},
       });*/
    }
  }
}
