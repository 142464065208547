import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {AuthHTTPService} from "../../../../auth/services/auth-http";
import {Router} from "@angular/router";
import {ErrorHandlingService} from "../../../../../shared/services/error-handling.service";
import {OrganizationHttpService} from "./organization-http.service";
import {UserType} from "../../../../auth";
import {catchError, finalize, map, switchMap} from "rxjs/operators";
import {JwtPayloadType} from "../../../../auth/models/auth.model";
import {OrganizationSizeModel, SectorModel} from "../../../models/sector.model";
import {OrganizationModel, OrganizationModelRequest} from "../../../models/organization.model";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentOrganization: BehaviorSubject<OrganizationModel> = new BehaviorSubject<OrganizationModel>(null);
  private currentOrganizationLocalStorageToken = `${environment.appVersion}-${environment.ORGANIZATION_KEY}`;


  constructor(
    private organizationHttpService: OrganizationHttpService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }


  set currentOrganizationValue(organization: OrganizationModel) {
    this.currentOrganization.next(organization);
  }

  get currentOrganizationValue(): OrganizationModel {
    return this.currentOrganization.value;
  }

  getAllSector(): Observable<SectorModel[]> {
    return this.organizationHttpService.getSector().pipe(
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      })
    );
  }


  getAllOrganizationSize(): Observable<OrganizationSizeModel[]> {
    return this.organizationHttpService.getOrganizationSize().pipe(
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      })
    );
  }


  createOrganization(data: OrganizationModelRequest): Observable<OrganizationModel> {
    this.isLoadingSubject.next(true);
    return this.organizationHttpService.createOrganization(data).pipe(
      map((data: OrganizationModel) => {
        this.setOrganiozationLocalStorage(data);
        return data;
      }),
      catchError((err) => {
        const errorMessage = this.errorHandlingService.handleError(err);
        return throwError(() => errorMessage);
      }),
      //finalize(() => this.isLoadingSubject.next(false))
    );
  }

  setOrganiozationLocalStorage(data: OrganizationModel) {
    localStorage.setItem(this.currentOrganizationLocalStorageToken, JSON.stringify(data))
  }


  getOrganizationLocalStorage(): OrganizationModel | undefined {
    try {

      const lsValue = localStorage.getItem(this.currentOrganizationLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }

  }


  getOrganizationByUser(id: string) {
    return undefined;
  }
}
