import {Injectable} from "@angular/core";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {StoreModel} from "../models/store.model";
import {Observable} from "rxjs";

const API_ORGANIZATION_URL = `${environment.organizationApiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class StoreServiceHttp {
  constructor(private readonly http: HttpClient) {
  }


  createStore(data: Omit<StoreModel, 'id'>): Observable<StoreModel> {
    return this.http.post<StoreModel>(`${API_ORGANIZATION_URL}/store/create`, data);
  }
}
