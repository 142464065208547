import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {

  codeVerificationRequest, JwtPayloadType,
  sendCodeVerificationRequest,
  SigninRequest,
  SignUpRequest
} from '../../models/auth.model';
import {UserType} from "../auth.service";

const API_USERS_URL = `${environment.accountApiUrl}`;
const API_AUTH_URL = `${environment.authApiUrl}`;
const API_NOTIFICATION_URL = `${environment.notificationApiUrl}`;
const API_ORGANIZATION_URL = `${environment.organizationApiUrl}`;


@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(data:SigninRequest): Observable<any> {
    return this.http.post<UserType>(`${API_AUTH_URL}/phone/login`, data);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: SignUpRequest): Observable<JwtPayloadType> {
    return this.http.post<JwtPayloadType>(`${API_AUTH_URL}/phone/register`, user);
  }


  sendPhoneVerificationCode(recipient:string): Observable<any> {
    return this.http.post(`${API_NOTIFICATION_URL}/sms/send-otp`, {
      recipient,
    });
  }

  sendEmailVerificationCode(recipient:string): Observable<any> {
    return this.http.post(`${API_NOTIFICATION_URL}/email/send-otp`, {
      recipient,
    });
  }

  verificationCode(data:codeVerificationRequest): Observable<any> {
    return this.http.post(`${API_AUTH_URL}/phone/verify`, data);
  }


  verificationEmailCode(data:codeVerificationRequest): Observable<any> {
    return this.http.post(`${API_AUTH_URL}/email/verify`, data);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<UserType> {
    return this.http.get<UserType>(`${API_USERS_URL}/me`);
  }

  getUserOrganization(token: string): Observable<any> {
    return this.http.get<any>(`${API_USERS_URL}/me/organization`);
  }

  loginVerify(data: codeVerificationRequest) {
    return this.http.post(`${API_AUTH_URL}/phone/login/verify`, data);
  }
}
