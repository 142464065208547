import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {OrganizationSizeModel, SectorModel} from "../../../models/sector.model";
import {OrganizationModel, OrganizationModelRequest} from "../../../models/organization.model";


const API_ORGANIZATION_URL = `${environment.organizationApiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class OrganizationHttpService {
  constructor(private readonly http: HttpClient) {
  }

  getSector(): Observable<SectorModel[]> {
    return this.http.get<SectorModel[]>(`${API_ORGANIZATION_URL}/sectors`);
  }

  getOrganizationSize(): Observable<OrganizationSizeModel[]> {
    return this.http.get<OrganizationSizeModel[]>(`${API_ORGANIZATION_URL}/get-organization-size`);
  }

  createOrganization(data: OrganizationModelRequest): Observable<OrganizationModel> {
    return this.http.post<OrganizationModel>(`${API_ORGANIZATION_URL}/create-organization`, data);
  }


  getUserOrganizations(): Observable<OrganizationModel[]> {
    return this.http.get<OrganizationModel[]>(`${API_ORGANIZATION_URL}/get-organization-by-user`);
  }
}
