import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from "@ngx-translate/core";

const defaultInternalServerError = 'Internal server error';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(private translate: TranslateService,) {
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      if (error?.error?.message && error?.error?.message !== defaultInternalServerError) {
        errorMessage = error.error.message;
      } else {
        this.translate.get('SHARED.ERRORS.ERROR_OCCURED').subscribe((res: string) => {
          errorMessage = res;
        })
      }

    } else {
      if (error.error.message && error.error.message !== defaultInternalServerError) {
        errorMessage = error.error.message;
      } else {
        this.translate.get('SHARED.ERRORS.ERROR_OCCURED').subscribe((res: string) => {
          errorMessage = res;
        })
      }
    }
    return errorMessage;
  }
}
