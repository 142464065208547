import { HttpClient } from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MediaModel} from "../../models/media.model";

const API_STORAGE_URL = `${environment.storageApiUrl}`;
@Injectable(
  {
    providedIn:'root'
  }
)
export class FileHttpService {
    constructor(private http: HttpClient) {
    }

    updateFile(file: File):Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<Observable<any>>(`${API_STORAGE_URL}/upload`, formData);
    }

     uploadMedia(file: File):Observable<MediaModel> {
      const formData = new FormData();
      formData.append('file', file);
      return  this.http.post<MediaModel>(`${API_STORAGE_URL}/upload-media`, formData);
    }

    deleteMedia(mediaId: string):Observable<any> {
      return this.http.delete(`${API_STORAGE_URL}/delete-media/${mediaId}`);
    }
}
