// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    LAYOUT:{
      HEADER:{
        LOGOUT:"Logout"
      }
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      VERIFY:{
        TITLE: 'Phone Number Verification',
        DESCRIPTION:'Enter the verification code sent by SMS to',
        TYPE_CODE: "Enter the 6-digit verification code",
        DONT_GET_CODE: "Didn't receive the code?",
        RESEND_CODE: "Resend",
        BUTTON: 'Send',
        LOADING_MESSAGE:"Verification in progress....",
        CODE_INVALID: 'The verification code is invalid',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    ORGANIZATION:{
      TITLE:"Let's set up your create-organization",
      SUBTITLE:"Provide information about your create-organization or business.",
      INPUT:{
        NAME:"Organization's name",
        SECTOR:"Industry sector",
        SIZE:"Size of the create-organization",
      },
    },
    SHARED: {
      LOADING_MESSAGE: 'Loading in progress...',
      ERRORS: {
        ERROR_OCCURED: 'An error occurred, please try again later.'
      },
      SUCCESS:{
        OTP_SEND_SUCCESS: 'Verification code has been sent successfully'
      },
      BTN:{
        LOGOUT:"Logout"
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: 'This field is required',
        MIN_LENGTH: 'The minimum length is {{requiredLength}} characters',
        AGREEMENT_REQUIRED: 'Acceptance of terms and conditions is required',
        NOT_FOUND: 'The requested {{name}} was not found',
        INVALID_LOGIN: 'The login details are incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'The maximum length is {{requiredLength}} characters',
        INVALID_FIELD: 'The field is not valid',
        INVALID_PHONE_NUMBER: 'The phone number is not valid',
        ERROR_OCCURED: 'An error occurred, please try again later.',
        ERROR_PASSWORD_MISMATCH: 'The passwords do not match',
        ONLY_LETTER_NUMBER:'Only letters and numbers are allowed',
        STORE_PATH:'The chosen link is not valid.',
      }
    }
  }
};
